.pageTour
{
    background: white;
    box-sizing: border-box;
    margin: 0px;
    text-align: left !important;
    color: var(--sdds-grey-700)!important;
    font-family: 'Scania Office';
}

.tourTitle
{
    background: white;
    box-sizing: border-box;
    margin: 0px;
    text-align: center;
    color: var(--sdds-blue-600);
    font-family: 'Scania Office';
}