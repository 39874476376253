.card {
    
    margin: var(--sdds-spacing-element-8);
    display: flex;
    flex-direction: column;
    border-radius: var(--sdds-spacing-element-4);
    background-color: var(--sdds-grey-50);
    padding: var(--sdds-spacing-element-16);
    .title-container {
        display: flex;

        .icon {
            width: var(--sdds-spacing-element-32);
            height: var(--sdds-spacing-element-32);
        }

        .card-title {
            margin: var(--sdds-spacing-element-4) var(--sdds-spacing-element-16);
            color: var(--sdds-grey-800)
        }
    }

    .sub-title {
        margin-left: var(--sdds-spacing-element-48);
        margin-bottom: var(--sdds-spacing-element-16);
        color: var(--sdds-grey-700)
    }

    .card-items {

        padding-left: var(--sdds-spacing-element-40);
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;

        .link-container {
            margin: var(--sdds-spacing-element-8);
            display: flex;

            .new-label{
                background-color: var(--sdds-positive);
                width:40px;
                height:20px;
                font-weight: bold;
                color:var(--sdds-white);
                padding:4px;
                border-radius: 4px;
                margin-left:8px;
                line-height: 12px;
            }

            .homepage-section-links {                
                color: var(--sdds-blue-600);               
            }

            &:visited {
                color: var(--sdds-blue-600);
            }

            .status {
                color: var(--sdds-negative);
                margin-left: var(--sdds-spacing-element-16);
            }

            .disabled {
                color: var(--sdds-grey-600);
                cursor: not-allowed;
            }
        }

    }
}