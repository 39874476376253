.main {
    background-color: var(--sdds-grey-50);
    padding: 4rem;
    border: 1px solid #f1f1f1;

    .title {
        font-family: 'Scania Office';
     

    }

    .user-export-btn-container {
        display: flex;
        margin-bottom: var(--sdds-spacing-element-24);
    }

    .btn-export,
    .spinner-container {
        margin: var(--sdds-spacing-element-16) 0;

    }

    .rolesWrapper {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 2rem;
    }

    .role {
        font-family: 'Scania Office';
        
        padding: 4rem 0;
    }

    .statistic {
        font-family: 'Scania Office';
               
    }
}