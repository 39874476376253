::-webkit-scrollbar
{
  width:20px;   
  background-color: white;
  margin-right:2px !important;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px; 
  border: 7.5px solid white;
  background-color: #CDD1DB;  
  height:50px;
}

body{
  background-color:var(--sdds-white)
}
.App{
  height: 100vh;
  overflow-y: hidden !important;
}