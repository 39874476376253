.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--sdds-grey-50);
    padding: 4rem;
    border: 1px solid #f1f1f1;
    font-family: 'Scania Office';
    font-size: 3.5rem;
    color:var(--sdds-grey-900);
    
    
    .column {
        flex: 49.99%;
        p span, div span {
            width: 150px;
            display: inline-block;
            margin-right: 10px;
            font-weight: bold;
            
        }
        .sub-param{
            color:var(--sdds-grey-700)
        }
        div {
            display: inline-block;
            padding: 3rem 0;

            div {
                display: block;
                padding: 0;
                div {
                    display: block;
                    padding: 0
                }
            }
          
        }
    }
}
