.landing-display {
    padding: 3rem;

    .search {
        padding: 3rem;
        background: var(--sdds-grey-100);
        position: relative;
    }
    .searchInput {
        width: calc(100% - 20px);
        border: 1px solid #CDD1DB !important;
        padding: 12px;
        background-color: #fff !important;
        border-radius: 0 !important;
        font-size: 3.5rem;
        text-overflow: ellipsis;
    }


    .dropDown {
        width: 98.7%;
        position: absolute;
        background-color: white;
        border: solid 1px #CDD1DB;
        border-top: none;
        left: 12px;
    }
    .suggess  {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #0D0F13;
        padding: 3rem;
        border-bottom: solid 1px #DBDFE6;
    }
}
