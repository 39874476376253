.homepage-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:93%;
    overflow-y: hidden;

    .title-and-search {
        width: 100vw;
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
       

        .cstt-title {
            color: var(--sdds-white);
            padding: var(--sdds-spacing-layout-32);
            top: 120px;
            opacity: 1;
            text-align: center;
        }

        .search-title {
            padding: var(--sdds-spacing-layout-16);
            color: var(--sdds-white);
        }

        .universal-search-container {
            width: 90vw;
            max-width: 756px;
        }

        background: url('./Homepage-bg.png') no-repeat center center;
        background-color: var(--sdds-blue-700);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .cards {
        overflow-y: scroll !important;
        width:100%;
        padding: var(--sdds-spacing-element-8);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr)); /* see notes below */
        grid-auto-rows: minmax(280px, auto);        
        overflow-x: hidden;
    }
}